import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'raw-data-expansion',
    templateUrl: './raw-data-expansion.component.html',
    styleUrls: ['./raw-data-expansion.component.css'],
    imports: [
        JsonPipe,
        MatExpansionModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RawDataExpansionComponent {
  @Input() data: any;
}
