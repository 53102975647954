import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'libTrim',
})
export class TrimPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (typeof value === 'string') {
      return value.replace(/\s+/g, ''); // Removes all spaces
    }
    return value;
  }

}
