import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Component as JtmsComponent } from '@dis/models';

@Component({
    selector: 'nde-dates',
    templateUrl: './nde-dates.component.html',
    styleUrls: ['./nde-dates.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DatePipe,
    ]
})
export class NdeDatesComponent {
  component = input.required<JtmsComponent>({alias: 'item'});

  ngOnInit() {
    console.log(this.component());

  }
}
