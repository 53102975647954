import { Timestamp } from "@angular/fire/firestore";

export enum COLLECTIONS {
  QUOTES = 'Quote',
  PROJECTS = 'Project',
  JOBS = 'Job',
  BUILDS = 'Build',
  ASSEMBLYS = 'Assembly',
  ASSEMBLIES = 'Assemblies',
  COMPONENTS = 'Component',
  PARTS = 'Part',
  CALIBRATIONS = 'Equipment Data',
  // Firestore Collections
  CONTAINERS = 'containers',
  INVOICES = 'invoices',
  EQUIPMENT = 'equipment',
}

export enum SSID {
  MASTER_TMS_CONTROLLER = '19SW5QI0qB9qNmk_XVtujpYaLGxD_l4hVE8wX2yZ84FE',
  QUOTES = '1JR1jQA8CBIfI_lVE76PC1eMb-NXPMc6GfsaYrYhRynE',
  PROJECTS = '1q2yAneEmLDh3paGp92QgXAIAoEJ0vLoLuIEeKSVzgrc',
  JOBS = '1WyQ19Pt9OhUurEqj6WF7Z4GCQ7XDbZFT_NhOExjTcG8',
  BUILDS = '1tf69KXuMXOUczaSzmeT05jGoWjRPzc9m1i0Z9eQV-j8',
  ASSEMBLIES = '1qqOpvjNHhO8hseSelz5-ql86iZK0VXmBHqsH2_MEqws',
  /**
   * @deprecated should just use ASSEMBLIES
   * @description this is only here incase its spelt this way in application
   */
  ASSEMBLYS = ASSEMBLIES,
  COMPONENTS = '1HcC0wHHsw5sK2bG1y0U-MPxQU2EAXxH5kALWJxLtaXw',
  PARTS = '1oqEG2sKuw01pOlG9F4k-h6CUp-gpQX84VrKGQkzyzP4',
  DRAWINGS = '1aeKCI-jmZks33wA89I8KminOURKs7-WBNDtzV3iiSrI',
  ASSEMBLER = '10R5H-IdSmuHMS5MjgOqd9g5fF_2qPFhh0okXsG3Rpwo',
  SCANS = '1oxlpDBraxAz_RgbMv1-H5DVHaymlt9KZCQBYxHyeoqs',
  CALIBRATIONS = '1ZvGCRmxeN1v7vVC-gfizghvUFm0BkRJ2I0ITQehczCM',
}

export enum DBSHEET {
  ARCHIVE = '[ARCHIVE] DAILY_SCRIPT_PROPERTIES',
  QUOTES = 'Quotes',
  PROJECTS = 'Projects',
  JOBS = 'Jobs',
  BUILDS = 'Builds',
  ASSEMBLIES = 'TEST_DATA',
  COMPNAMEMATRIX = 'DATA_EXPORT',
  COMPONENTS = 'Components',
  PARTS = 'Parts',
  ASSEMBLER = 'Assembler',
  MANAGER = 'Scans',
  CALIBRATIONS = 'Equipment Data',
}

export enum FOLDERID {
  ADMIN = '0AKnI6TkJfgpFUk9PVA',
  PROJECTS = '0ACTtDTkgZ7xrUk9PVA',
  BUILDSCOLLECTION = '1Upk6PBpoj0LtALK5KUQOQOdtpSWGg_WZ',
  COMPONENTS = '0AGhKCCV_S34yUk9PVA',
  THD_REPORT = '1vK37MuOvrP_WyPR5tTWDvKAab8gIcpZm',
  THD_REWORK = '1vK37MuOvrP_WyPR5tTWDvKAab8gIcpZm',
  PACKING_LIST = '1qKT-VcKLr7s3dTQoyVsEAAMwBJ-8y7zS',
  TRACE = '0ALAIudzrL3LfUk9PVA',
  COC = '1EExNLOcsE2tDbuaG3uJLrwDNVJbprs3o',
  SCRAP_DOC = '1ZaTMn9pVrY-e7nlsmpr6WbVpwBN4sO1_',
  SEAL_COC = '1Yb8OV3Dr7m3XxdryjrerbqtwY6XfQWIc',
  MTR = '1yWCy0OYkqYtRmfmFuvQORUR822tl1EFO',
  SCANS = '0AKzcoWqJrnBvUk9PVA',
  NDE = '1C8iJkZPuqVyacjYHK9_drgGrrkAGdR0x',
  PARTIMAGES = "1xthdHpchGQTV6E5NKnQhnRjuvvJsn5QI",
  PURCHASEORDER = '0AAVmzNSLHSbZUk9PVA',
  STAGEDBUILDS = '0AOn87SQJ-sJFUk9PVA',
  DEL_TKT = '13dK8-RyyfU291frEOdqSPEW421gl95sb',
  REC_TKT = '1OOBtZrzErOhYhJUXPz_wG4X8ABS2lg8l',
  DIM_LOG = '1FevHR0mK9ck0ynFxbCoUD9UbHVJW6LuR',
  DRIFT_REPORT = '1LaBz1KDKHwhBUtmFnvt91htPrbjO92LV',
  PO = PURCHASEORDER,
  PO18 = "1EUtVCt_T6vOcDveot5xDmTBBDz68oHWu",
  PO19 = "1m07aYEh-zvftaNZ8DNskpWDmXt1zbsv5",
  PO20 = "1DAmGJpHW6FMGxKIcySUtaJhkz-zFW72j",
  PO21 = "1w13kg0sZHljUGRYsUkvjmw2q68x1ZeFK",
  PO22 = "1ai70wCqd9RRCB5MF9h_sd1aCHO2jd8Ap",
  PO23 = "1rpgU2CLIKOYHEyyHSmvL_fnwRnjXzlof",
  PO24 = '1dV_DIcjtxO8EZGmfzWzwmEIerws40597',
  PO25 = '1Hl_K0204YICZJFo1zOCCJdWneaEy3M5B',
}

export enum BOMID {
  CRC = '1nXgDlxT2q6Vvl4XI5PVl_HsHUciK9oOW6Ty2RkCrrqA',
  ESJ = '',
  CRCIS = '',
  DSFV = ''
}

export class BaseData {
  uid: string;
  updatedAt: Date;
  readonly rowIndex: number;
  readonly createdAt: Date;

  constructor(data: any) {
    this.uid = data.uid || null;
    this.updatedAt = data.updatedAt || null;
    this.rowIndex = data.rowIndex || null;
    this.createdAt = data.createdAt || null;
  }
}

export function toBoolean(value: string) {
  return value === 'TRUE' ? true : false;
}

/**
 * @deprecated Can call .millis() at the end of timestamp now, no reason to have to convert anymore
 * @param  {any} date
 */
export function convertToFirestoreTimestamp(date: any) {
  if (date && !isFirestoreTimestamp(date)) {
    date = Timestamp.fromDate(new Date(date));
  } else if (!date) {
    return date = new Date('1/1/1970')
  }
  return date;
}

export const isFirestoreTimestamp = (
  timestamp: any
): timestamp is Timestamp => {
  return (
    typeof timestamp === 'object' && timestamp.seconds && timestamp.nanoseconds
  );
};

export interface ArrayDate {
  start: Date;
  stop: Date;
}

export interface IArrayDate {
  start: Timestamp;
  stop: Timestamp;
}
