import { AssemblyComponent } from './assembly';
import { BuildInvoiceCyle } from './build.invoice_cycle';
import { ArrayDate, BaseData } from './_global';
import { DRIVE_FOLDER_URL } from '@dis/gapi';
import { FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from '@angular/fire/firestore';

export interface Build extends BaseData {
  buildNumber: string;
  serialNumber: string;
  assemblyNumber: string;
  assemblyCard: string;
  description: string;
  folderId: string;
  toolType: string;
  buildComponents: BuildComponent[];
  components: string;
  statuses: BuildStatuses;
  status: string;
  list: string;
  projectNumber: string;
  jobNumber: string;
  jobUid: string;
  projectNumber_jobNumber: string;
  loadout: Loadout;
  invoices: BuildInvoiceCyle[];
  dateOut: string;
  dateIn: string;
  notes: string;
  finalized: boolean;
  buildStatus: string;
  shipmentStatus: string;
  billingStatus: string;
  notesArray: any[];
  buildDate: string;
  functionTestCard: string;
  ranBoolean: boolean;
  rereleaseBoolean: boolean;
  approval: boolean;
  metadata: BuildMetadata;
}

export interface BuildComponent {
  assemblyComponent: AssemblyComponent;
  components: string | string[];
  multiBool: boolean;
}
export class Build extends BaseData {
  constructor(
    data: Partial<Build> = {},
  ) {
    super(data);
    this.uid = data.buildNumber || null;
    this.buildNumber = data.buildNumber || data.uid || null;
    this.assemblyNumber = data.assemblyNumber || null;
    this.assemblyCard = data.assemblyCard;
    this.description = data.description || null;
    this.serialNumber = data.serialNumber || null;
    this.folderId = data.folderId || data.buildFolderId || null;
    this.toolType = data.toolType || null;
    this.buildComponents = data.buildComponents || [];
    this.components = data.components || '';
    this.list = data.list || null;
    this.projectNumber = data.projectNumber || null;
    this.jobNumber = data.jobNumber || null;
    this.jobUid = data.jobUid || null;
    this.projectNumber_jobNumber = data.projectNumber_jobNumber || null;
    this.dateOut = data.dateOut || null;
    this.dateIn = data.dateIn || null;
    this.notes = data.notes || null;
    //@ts-expect-error
    this.finalized = data.finalized == "TRUE" ? true : false || false;
    this.loadout = data.loadout || {
      projectNumber: data.projectNumber || null,
      jobNumber: data.jobNumber || null,
      shippingContainer: null,
      outDate: null,
      inDate: null,
      status: null,
    };
    this.status = data.status || null;
    this.statuses = data.statuses || null;
    this.buildStatus = data.buildStatus || null;
    this.shipmentStatus = data.shipmentStatus || null;
    this.billingStatus = data.billingStatus || null;
    /**
    * @deprecated not sure if even being used;
    */
    this.invoices = [];
    this.notesArray = this.getBuildNotesArray(this.notes);
    this.buildDate = data.buildDate || null;
    this.functionTestCard = data.functionTestCard || null;

    this.ranBoolean = typeof data.ranBoolean === 'string' ?
      data.ranBoolean === "TRUE" ? true : false
      : typeof data.ranBoolean === 'boolean' ? data.ranBoolean : null;

    this.rereleaseBoolean = typeof data.rereleaseBoolean === 'string' ?
      data.rereleaseBoolean === "TRUE" ? true : false
      : typeof data.rereleaseBoolean === 'boolean' ? data.rereleaseBoolean : null;

    this.approval = typeof data.approval === 'string' ?
      data.approval === "TRUE" ? true : false
      : typeof data.approval === 'boolean' ? data.approval : null;


    this.metadata = data.metadata || null;

    this.fromSpreadsheet(data);
  }


  /**
   * Used for naming documents and folders, use this for consitenacy in naming
   */
  public get title() {
    return this.buildNumber;
  }

  /**
   * @deprecated use folderId instead;
   * Can probably be deleted
   */
  public get buildFolderId() {
    return this.folderId;
  }

  public get folderUrl() {
    return `${DRIVE_FOLDER_URL}${this.folderId}`;
  }

  private fromSpreadsheet(build: Partial<Build>) {
    if (typeof build.statuses === 'string') {
      this.statuses = JSON.parse(build.statuses);
    }
    if (typeof build.metadata === 'string') {
      this.metadata = JSON.parse(build.metadata);
    }
  }

  static toSpreadsheet(build: Build) {
    //@ts-expect-error
    build.statuses = JSON.stringify(build.statuses);
    //@ts-expect-error
    build.metadata = JSON.stringify(build.metadata);
    return build;
  }

  private getBuildNotesArray(noteString: string) {
    if (noteString) {
      const noteArray = noteString.split('_');
      return noteArray;
    } else {
      const noteArray = [];
      return noteArray;
    }
  }

  // static converter: FirestoreDataConverter<Build> = {
  //   fromFirestore: (
  //     snapshot: QueryDocumentSnapshot<Build>,
  //     options: SnapshotOptions): Build => {
  //       const data = snapshot.data(options);
  //       const build = new Build(data);
  //       return build;
  //   },
  //   toFirestore: (build: Build): Build => {
  //     return build;
  //   }
  // }
}


export interface BuildMetadata {
  booleanMetadata: BuildBooleanMetadata;
  dateMetadata: BuildDateMetadata;
}

export interface BuildBooleanMetadata {
  approved: boolean;
  assemblyRequired: boolean;
  assemblyComplete: boolean;
  testingRequired: boolean;
  testingComplete: boolean;
  buckingRequired: boolean;
  buckingComplete: boolean;
  finalized: boolean;
  buildForInternalTesting: boolean;
  retest: boolean;
  reRelease: boolean;
  ranBoolean: boolean;
  archiveBool: boolean;
}

export interface BuildDateMetadata {
  assemblyDates: ArrayDate;
  testingDates: ArrayDate;
  buckingDates: ArrayDate;
}

export interface BuildStatuses {
  assemblyStatus: BUILD_ASSEMBLY_STATUS;
  billingStatus: BUILD_BILLING_STATUS;
  buildStatus: BUILD_BUILDS_STATUS;
  componentStatus: BUILD_COMPONENTS_STATUS;
  locationStatus: BUILD_LOCATION_STATUS;
  shipmentStatus: BUILD_SHIPMENT_STATUS;
  status: string;
}
export interface Loadout {
  projectNumber: string;
  jobNumber: string;
  shippingContainer: string;
  outDate: Date;
  inDate: Date;
  status: string;
}

export interface BuildComponent {
  assemblyComponent: AssemblyComponent;
  components: string | string[];
  multiBool: boolean;
}


export enum BUILD_ASSEMBLY_STATUS {
  NONE = "NONE",
  MANUFACTORING = "MANUFACTORING",
  INSPECTION_PROGRAM = "INSPECTION PROGRAM",
  RFP = 'RFP',
  PULLED = 'PULLED',
  RFA = 'RFA',
  ASSEMBLING = 'ASSEMBLING',
  ASSEMBLED = 'ASSEMBLED',
  REDRESSING = 'REDRESSING',
  REDRESSED = 'REDRESSED'
}

export enum BUILD_BUILDS_STATUS {
  NONE = "NONE",
  CREATED = "CREATED",
  COMPLETE = "COMPLETE",
  VOID = "VOID",
  TRANSFERRED = "TRANSFERRED",
}

export enum BUILD_BILLING_STATUS {
  NONE = "NONE",
  QUOTED = "QUOTED",
  INVOICED = "INVOICED",
  PAID = "PAID",
  NO_INVOICE = "NO INVOICE"
}

export enum BUILD_COMPONENTS_STATUS {
  NONE = "NONE",
  ORDERED = "ORDERED",
  RECEIVED = "RECEIVED",
  DAMAGED = "DAMAGED",
  INSPECTED = "INSPECTED"
}

export enum BUILD_LOCATION_STATUS {
  NONE = "NONE",
  DIS_LFT = "DIS LFT",
  TRANSIT = "TRANSIT",
  ON_SITE = "ON SITE",
  VENDOR = "VENDOR",
  CUSTOMER = "CUSTOMER",
  UNKNOWN = "UNKNOWN",
}

export enum BUILD_SHIPMENT_STATUS {
  NONE = "NONE",
  ASSIGNED = "ASSIGNED",
  RTS = "RTS",
  SHIPPED = "SHIPPED",
  RECIEVED = "RECIEVED",
  DOWNHOLE = "DOWNHOLE",
  LIH = "LIH",
  LOST = "LOST",
  IN_CUST_POS = "IN CUST POS",
}

export const BUILD_ICON = {
  ASSEMBLING: 'flex_direction',
  ASSEMBLED: 'list_alt_check',
  ASSIGNED: 'assignment_turned_in',
  BUILD: 'build',
  CREATED: 'add_circle',
  CUSTOMER: 'domain',
  DAMAGED: 'destruction',
  DIS_LFT: 'home',
  DOWNHOLE: 'oil_barrel',
  IN_CUST_POS: 'for_you',
  INSPECTED: 'rubric',
  INSPECTION_PROGRAM: 'rubric',
  INVOICED: 'paid',
  LIH: 'oil_barrel',
  LOST: 'help',
  MANUFACTORING: 'factory',
  NONE: 'block',
  NO_INVOICE: 'money_off',
  ON_SITE: 'domain',
  ORDERED: 'box',
  PAID: 'paid',
  PULLED: 'approval_delegation',
  QUOTED: 'request_quote',
  RECEIVED: 'call_received',
  REDRESSING: 'tv_options_edit_channels',
  REDRESSED: 'tv_options_edit_channels',
  RFA: 'view_list',
  RFP: 'trackpad_input',
  SHIPPED: 'local_shipping',
  TRANSIT: 'airport_shuttle',
  VENDOR: 'domain',
}

export const BUILD_PIPLINE_STATUSES = {
  ASSIGNED: {
    icon: BUILD_ICON.ASSIGNED,
    iconColor: 'black',
    statusColor: 'gold'
  }
}

