@let files = csvFiles();

@defer {
@if(files.length === 0) {
<p>No files found</p>
} @else {

@switch (printView()) {
@case (true) {
<lib-print-a11-template>
  @for (file of files; track file.Id) {
  <lib-view-graph [file]="file"></lib-view-graph>
  }
</lib-print-a11-template>
}
@default {
@for (file of files; track file.Id) {
<lib-view-graph [file]="file" [type]="type()"></lib-view-graph>
}
}
}

}

} @placeholder (minimum 1s) {
<p>Function and Pressure Graphs</p>
} @loading (minimum 1s; after 500ms) {
<p>Loading graphs...</p>
} @error {
<p>Failed to load graphs</p>
}
