@let item = component();
@if(item.lastNdeDate) {
  <span class="chip">
    {{item.nde.lastNdeDate | date: 'shortDate'}}
  </span>
   <span>/</span>
  <span class="chip"
    [class.two-weeks-out]="item.nde.inspectionLessThan2Weeks"
    [class.expired]="item.nde.isExpried">
    {{item.nde.sixMonthInspectionDate | date: 'shortDate'}}
  </span>
}
