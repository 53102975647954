<table mat-table [dataSource]="dataSource()" class="mat-elevation-z8">


  <mat-text-column name="serialNumber"></mat-text-column>
  <mat-text-column name="traceNumber"></mat-text-column>
  <mat-text-column name="disPoNumber"></mat-text-column>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openUrl(row.traceUrl)">
  </tr>
</table>
