import { Component, inject } from "@angular/core";
import { Firestore, doc, docData } from "@angular/fire/firestore";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";

import { MatFormFieldModule } from "@angular/material/form-field";

import { Observable, firstValueFrom, lastValueFrom, map, of, switchMap, take, tap } from "rxjs";

import { FormType, createForm } from "ngx-sub-form";
import { NoopValueAccessorDirective } from "../../noop-value-accessor.directive";
import { AppMetadataCollection } from "../../../db";
import { MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";
import { AsyncPipe } from "@angular/common";

interface ThisControl {
    customer: string;
}

@Component({
    selector: 'control-select-customer',
    imports: [
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatOptionModule,
        AsyncPipe
    ],
    templateUrl: './customer-select.control.html',
    styleUrls: ['./customer-select-control.css'],
    providers: [
        NoopValueAccessorDirective
    ]
})
export class CustomerSelectControl {  

    customers$ = inject(AppMetadataCollection)
        .customers$
        .pipe(
            tap(data => console.log(data.options)),
            map(data => data.options.sort((a,b) => {
                return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0;
            })),
        )

    public form = createForm<ThisControl>(this, {
        formType: FormType.SUB,
        formControls: {
            customer: new FormControl(null, Validators.required)
        }
    })
}   
