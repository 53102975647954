import { ChangeDetectionStrategy, Component, inject, Inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { Component as JTMSComponent } from '@dis/models';
import { Store } from '@ngxs/store';
import { BuildsSelectors, ComponentsState } from '../../jtms';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'lib-traceabilites-table',
  imports: [
    MatIconModule,
    MatTableModule,
  ],
  templateUrl: './traceabilites-table.component.html',
  styleUrl: './traceabilites-table.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TraceabilitesTableComponent {
  dataSource = signal<JTMSComponent[]>([]);
  displayedColumns: (keyof JTMSComponent)[] = [
    'serialNumber',
    'traceNumber',
    'disPoNumber',
  ];

  private store = inject(Store);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    this.getComponentsFromJobBuilds(data);
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

  private getComponentsFromJobBuilds(jobNumber: string) {
    // Get all components from job
    const builds = this.store.selectSnapshot(BuildsSelectors.getByJobNumber(jobNumber));
    this.dataSource.set(
      builds.map(build => this.store.selectSnapshot(ComponentsState.getById(build.serialNumber)))
    )
  }
}
