import { ChangeDetectionStrategy, Component, inject, input, signal } from '@angular/core';
import { FileInfo, GoogleDriveApiService } from '@dis/gapi';
import { GoogleChartsModule } from 'angular-google-charts';
import { ViewGraphComponent } from '../view-graph/view-graph.component';
import { PrintA11TemplateComponent } from "../../print-templates/print-A11-template/print-A11-template.component";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@Component({
    selector: 'lib-view-graphs',
    imports: [
        GoogleChartsModule,
        FormsModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatToolbarModule,
        ViewGraphComponent,
        PrintA11TemplateComponent,
    ],
    templateUrl: './view-graphs.component.html',
    styleUrl: './view-graphs.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewGraphsComponent {

  csvFiles = signal<FileInfo[]>([]);
  folderId = input<string>();
  driveApi = inject(GoogleDriveApiService);
  printView = input<boolean>(false);
  type = input<'psi' | 'force'>('force');

  async ngOnInit() {
    let files = await this.driveApi.getFiles(this.folderId());
    files = files.filter(file => file.MimeType === 'text/csv');
    this.csvFiles.set(files);
  }

}
