import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';

import { Part } from '@dis/models';
import { PartsState } from '../../jtms';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'part-image',
    templateUrl: './part-image.component.html',
    styleUrls: ['./part-image.component.scss'],
    imports: [
        NgOptimizedImage,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartImageComponent implements OnInit {

  partNumber = input.required<string>();

  // Optional inputs
  rotateImage = input<boolean>(false);
  showConnections = input<boolean>(false);
  height = input<number>(150);
  width = input<number>(50);

  part: Part;

  constructor(
    private store: Store,
  ) {

  }

  get imageUrl() {
    const options = [
      this.width() ? `width=${this.width()}` : null,
      this.height() ? `height=${this.height()}` : null,
      this.rotateImage() ? 'rotate' : null,
    ].filter(Boolean).join(",");
    return `https://jtms.drillingllc.com/cdn/images/tools/${options}/${this.partNumber()}.png`
  }

  ngOnInit() {
    this.part = this.store.selectSnapshot(PartsState.getById(this.partNumber()))
  }

}
