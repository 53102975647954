import { Component, Output, EventEmitter } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'delete-button',
    imports: [
        // Material Imports
        MatButtonModule,
        MatTooltipModule,
        MatIconModule
    ],
    templateUrl: './delete-button.component.html',
    styleUrls: ['./delete-button.component.css']
})
export class DeleteButtonComponent {
  canDelete!: boolean;

  @Output() delete = new EventEmitter<boolean>();

  cancel() {
    this.canDelete = false;
  }

  prepareForDelete() {
    this.canDelete = true;
  }

  deleteItem() {
    this.delete.emit(true);
    this.canDelete = false;
  }


}
